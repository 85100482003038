import { computed, ref, watch } from '@vue/composition-api'
import store from '@/store'
import http from '@/global/http'
import useProduct from '@/views/campaign/useCampaign'
import productFilterModal from '@/modal/productFilterModal'

export default function useCampaignList() {
  const {
    productMenuType,
    productMenuTypeVI,
    productListMenuType,
    //
    exportExcel,
    renderProductStructure,
    deleteProduct,
  } = useProduct()

  const filter = productFilterModal()
  const products = ref([])
  const currentProduct = ref({})
  const created = ref(false)
  const exportExcelTime = ref(null)
  const timeFrom = ref(null)
  const timeTo = ref(null)
  const exportExcelCloseDeal = ref('')

  const userData = computed(() => store.state.app.userData)
  const districts = computed(() => store.state.app.districts)
  const wardsByDistricts = computed(() => store.state.app.wardsByDistricts)
  const streetsByDistricts = computed(() => store.state.app.streetsByDistricts)
  const users = computed(() => store.state.app.users)
  const routeName = computed(() => store.state.app.currentRouteName)

  const showImage160 = ref([])
  const supporterAdmin = ref([])
  const campaignForm = ref({
    name: '',
    start_at: '',
    end_at: '',
    creator_id: '',
    distribute_users: [],
    product_count: 0,
    query: {},
  })

  // Table products
  const tableColumns = ref([
    {
      label: 'Mã',
      key: 'id',
      tdClass: 'minw-80 sticky-column-left',
      thClass: 'sticky-column-left',
    },
    {
      label: 'Ảnh',
      key: 'image',
    },
    {
      label: 'Tiêu đề',
      key: 'title',
      thClass: 'minw-250',
    },
    {
      label: 'Kết cấu',
      key: 'ket-cau',
      formatter: (value, key, item) => renderProductStructure(item),
    },
    {
      label: 'Giá tổng',
      key: 'price',
    },
    {
      label: 'Người đăng',
      key: 'nguoi-dang',
      tdClass: 'position-relative',
    },
    {
      label: 'Loại',
      key: 'type',
    },
    {
      label: 'Thang máy',
      key: 'thang-may',
    },
    {
      label: 'Mở rộng',
      key: 'expand_style',
    },
    {
      label: 'Ngày đăng',
      key: 'ngay-dang',
    },
    {
      label: 'Hành động',
      key: 'actions',
      tdClass: 'sticky-column-right text-right',
      thClass: 'sticky-column-right',
    },
  ])
  const perPage = ref(50)
  const perPageOptions = [10, 25, 50, 100]
  const currentPage = ref(1)
  const totalProducts = ref(0)

  const setQueryForCreateCampaign = queryString => {
    campaignForm.value.query = queryString
  }
  const setUserCreateCampaign = user => {
    let createId = user && user.id
    if (!createId) {
      createId = userData.value.id
    }
    campaignForm.value.creator_id = createId
  }

  const createCampaign = () => {
    const data = campaignForm.value
    const productsCount = totalProducts.value
    data.product_count = productsCount
    data.distribute_users = supporterAdmin.value.map(user => ({
      id: user.id,
      product_count: Math.ceil(productsCount / supporterAdmin.value.length),
    }))

    return http.handle(store.dispatch('campaign/createCampaign', data))
  }
  const setDistributeUsersCreateCampaign = (userPick = []) => {
    const userList = userPick || supporterAdmin.value || []
    const productCount = Math.ceil(products.value.length / userList.length)

    campaignForm.value.distribute_users = userList.map(item => ({
      user_id: item.id,
      product_count: productCount,
    }))
  }

  const buildQuery = customFilter => http.buildQuery(customFilter || Object.assign(filter, {
    page: currentPage.value - 1,
    perpage: perPage.value,
    user_id: (() => {
      if (!productListMenuType.value) return ''
      return productListMenuType.value === 'list' ? filter.user_id : userData.value.id
    })(),
    type_product: 'hired',
    formality: 'cho-thue',
    // currency: 'usd',
  })) || ''
  const fetchProducts = customFilter => {
    const queryString = buildQuery(customFilter)

    setQueryForCreateCampaign(queryString)

    return http.handle(store.dispatch('campaign/fetchProducts', queryString), res => {
      products.value = res.data?.result || []
      totalProducts.value = res.data?.total
    })
  }

  const exportExcelProduct = () => exportExcel(buildQuery({
    type_product: productMenuType.value,
    close_deal_type: exportExcelCloseDeal.value,
    user_id: (() => {
      if (!productListMenuType.value) return ''
      return productListMenuType.value === 'list' ? '' : userData.value.id
    })(),
    from: new Date(timeFrom.value).toISOString(),
    to: new Date(timeTo.value).toISOString(),
  }))

  const clearFilter = initFilter => {
    Object.assign(filter, productFilterModal(), initFilter)
  }

  watch(exportExcelTime, n => {
    const today = new Date()
    if (!n) {
      timeFrom.value = null
      timeTo.value = null
    } else if (n === 'week') {
      timeFrom.value = new Date(today.getTime() - 7 * 24 * 60 * 60000)
      timeTo.value = new Date(today)
    } else if (n === 'month') {
      timeFrom.value = new Date(today.getTime() - 30 * 24 * 60 * 60000)
      timeTo.value = new Date(today)
    }
  })
  watch([currentPage, perPage], () => {
    fetchProducts()
  })
  watch(() => filter.province_id, n => {
    if (!n) return
    store.dispatch('app/fetchDistricts', { province_id: n })
      .then(res => {
        const arr = res.data?.data || []
        store.dispatch('app/fetchStreetsByDistricts', http.buildQuery({
          district_ids: arr.map(item => item.id)
            .join(','),
        }))
      })
    if (created.value) {
      filter.district_id_in = []
      filter.street_name_in = []
    }
  })
  watch(() => filter.district_id_in, n => {
    if (!n || !filter.province_id) return
    store.dispatch('app/fetchWardsByDistricts', http.buildQuery({ district_ids: n }))
    if (created.value) {
      filter.ward_id_in = []
    }
  })

  return {
    showImage160,
    filter,
    districts,
    wardsByDistricts,
    streetsByDistricts,
    users,
    productMenuType,
    productMenuTypeVI,
    totalProducts,
    products,
    tableColumns,
    perPage,
    currentPage,
    perPageOptions,
    userData,
    routeName,
    currentProduct,
    productListMenuType,
    timeTo,
    timeFrom,
    exportExcelTime,
    exportExcelCloseDeal,
    created,
    campaignForm,
    supporterAdmin,
    clearFilter,
    buildQuery,
    // api
    createCampaign,
    exportExcelProduct,
    fetchProducts,
    deleteProduct,
    setUserCreateCampaign,
    setDistributeUsersCreateCampaign,
  }
}
